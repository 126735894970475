import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr/dist/flatpickr'
import { Greek } from 'flatpickr/dist/l10n/gr'

export default class extends Controller {
  connect() {
    const wrap = this.element.tagName !== 'INPUT'

    this.inputElement = wrap ? this.element.querySelector('[data-input]') : this.element
    this.clearElement = wrap ? this.element.querySelector('[data-clear]') : null
    this.todayElement = wrap ? this.element.querySelector('[data-today]') : null

    const options = {
      altFormat: 'd/m/Y',
      altInput: true,
      allowInput: true,
      onOpen: function (selectedDates, dateStr, instance) {
        instance.altInput.readOnly = true
      },
      onClose: function (selectedDates, dateStr, instance) {
        instance.altInput.readOnly = false
        instance.altInput.blur()
      },
      wrap: wrap,
      locale: (document.documentElement.lang === 'el') ? Greek : 'default'
    }

    this.flatpickr = flatpickr(this.element, options)

    this.inputElement.classList.add('cursor-default')
    this.inputElement.addEventListener('change', this.toggleClear.bind(this))
    this.inputElement.addEventListener('change', this.toggleToday.bind(this))
    this.todayElement?.addEventListener('click', this.setToday.bind(this))

    this.toggleToday()
  }

  disconnect() {
    flatpickr(this.element).destroy()

    this.inputElement.removeEventListener('change', this.toggleClear.bind(this))
    this.inputElement.removeEventListener('change', this.toggleToday.bind(this))
    this.todayElement?.removeEventListener('click', this.setToday.bind(this))
  }

  toggleClear() {
    if (!this.clearElement) return

    if (this.inputElement.value) {
      this.clearElement.classList.remove('d-none')
    } else {
      this.clearElement.classList.add('d-none')
    }
  }

  toggleToday() {
    if (!this.todayElement) return

    if (this.inputElement.value === this.todayElement.dataset.today) {
      this.todayElement.classList.add('d-none')
    } else {
      this.todayElement.classList.remove('d-none')
    }
  }

  setToday(e) {
    if (!this.todayElement) return
    if (e) e.preventDefault()

    this.flatpickr.setDate(this.todayElement.dataset.today, true)
  }

  clearValue() {
    this.flatpickr.clear()
  }
}
