import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lineUnitPrice", "lineVat", "lineNet", "vatRate", "series", "num", "codeOutput", "total",
    "subtotal", "accommodationTax", "dayCount", "checkIn", "checkOut", "autoAccommodation", "showHideAccommodation",
    "lineDescription", "vatRateOutput", "sendIcon", "sendToggle", "netTotal", "vatTotal"]
  static values = { accommodationTaxRateUrl: String, descriptionTemplate: String }

  connect() {
    this.updateInvoiceCode()
    this.calculateValues()
    this.handleSendToggle()

    this.lineDescriptionTarget.dataset.placeholder = this.lineDescriptionTarget.placeholder
    this.setLineDescription()
  }

  async updateAccommodationTax() {
    await this.updateAccommodationTaxRateCents()

    if (this.autoAccommodationTarget.checked) {
      this.showHideAccommodationTargets.forEach(elem => {elem.classList.toggle("d-none", false)})
      if (this.checkInTarget.value === "" || this.checkOutTarget.value === "") {
        this.dayCountTarget.innerText = "-"
        this.accommodationTaxTarget.innerText = "-"
      } else {
        const date1 = new Date(this.checkInTarget.value)
        const date2 = new Date(this.checkOutTarget.value)
        const timeDifference = date2.getTime() - date1.getTime()
        const daysDifference = timeDifference / (1000 * 3600 * 24)
        this.dayCountTarget.innerText = daysDifference
        this.accommodationTaxTarget.innerText = daysDifference > 0 ?
          daysDifference * (this.accommodationTaxRateCents / 100) : "-"
      }
    } else {
      this.showHideAccommodationTargets.forEach(elem => {elem.classList.toggle("d-none", true)})
      this.accommodationTaxTarget.innerText = "-"
    }
  }

  setLineDescription() {
    const fromDate = this.formatDateOrBlank(this.checkInTarget.value)
    const toDate = this.formatDateOrBlank(this.checkOutTarget.value)
    let result = this.lineDescriptionTarget.dataset.placeholder

    if (fromDate && toDate) {
      result = this.descriptionTemplateValue
        .replace("%{from}", fromDate)
        .replace("%{to}", toDate)
    }

    this.lineDescriptionTarget.placeholder = result
  }

  updateInvoiceCode() {
    const series = this.hasSeriesTarget ? this.seriesTarget.innerText : ""
    const num = this.hasNumTarget ? this.numTarget.value : ""
    this.codeOutputTarget.innerText = (series == "0" ? "" : series) + `${num.padStart(6, "0")}`
  }

  handleSendToggle() {
    this.sendIconTarget.classList.toggle("d-none", !this.sendToggleTarget.checked)
  }

  async calculateValues() {
    await this.updateAccommodationTax()

    const vatRate = Number(this.vatRateTarget.value)
    this.vatRateOutputTarget.innerText = vatRate
    if (!this.validateValueNumbers(
      [this.vatRateTarget.value,
        this.lineUnitPriceTarget.value])) return this.invalidInput()

    const total = Number(this.lineUnitPriceTarget.value).toFixed(2)
    const netPrice = (total / (1 + vatRate / 100)).toFixed(2)
    const vatAmount = (total - netPrice).toFixed(2)
    const accommodationTax = Number(this.accommodationTaxTarget.innerText) || 0
    this.lineNetTarget.value = `€ ${netPrice}`
    this.lineVatTarget.value = `€ ${vatAmount}`
    this.subtotalTarget.innerText = total
    this.netTotalTarget.innerText = netPrice
    this.vatTotalTarget.innerText = vatAmount
    this.totalTarget.innerText = (Number(total) + Number(accommodationTax)).toFixed(2)
  }

  invalidInput() {
    this.lineVatTarget.value = "-"
    this.lineNetTarget.value = "-"
    this.netTotalTarget.innerText = "-"
    this.vatTotalTarget.innerText = "-"
    this.totalTarget.innerText = "-"
    this.subtotalTarget.innerText = "-"
  }

  handleUnitPriceChanged() {
    this.calculateValues()
  }

  validateValueNumbers(values) {
    for (let i = 0; i < values.length; i++) {
      if (values[i] === "" || Number.isNaN(values[i])) return false
    }
    return true
  }

  formatDateOrBlank(str) {
    if (str === undefined || str === "") return ""

    const date = new Date(Date.parse(`${str}T00:00:00.000Z`))
    const year = date.getUTCFullYear()
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0")
    const day = date.getUTCDate().toString().padStart(2, "0")

    return [day, month, year].join("/")
  }

  async updateAccommodationTaxRateCents() {
    const checkIn = this.checkInTarget.value
    this.accommodationTaxRateCheckIn ||= null

    if (this.accommodationTaxRateCheckIn === checkIn) return

    const accommodationTaxRateUrl = new URL(this.accommodationTaxRateUrlValue)
    accommodationTaxRateUrl.searchParams.set('date', this.checkInTarget.value)

    await fetch(accommodationTaxRateUrl.toString())
      .then(response => response.json())
      .then(data => {
        this.accommodationTaxRateCheckIn = checkIn
        this.accommodationTaxRateCents = data.rate
      })
  }
}
